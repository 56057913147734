import React, { Suspense, createContext, useEffect, useState } from "react"
import pageBuilder from "./Builder/index"
import { graphql } from "gatsby"
import "bootstrap/dist/css/bootstrap.min.css"
import { sortBy } from "lodash"
import SSRProvider from "react-bootstrap/SSRProvider"
import loadable from "@loadable/component"
import { ProviderAPI } from "./PageStateContext"
import {
  GoogleReCaptchaProvider,
  GoogleReCaptcha,
} from "react-google-recaptcha-v3"
import { setCaptchaResponse } from "../redux/slices/states"
import Translate from "./Translation"
import { useDispatch } from "react-redux"
const FontSchemeManager = loadable(() => import("./FontScheme/index"))
const PrimeAgileHelmet = loadable(() => import("./Helmet/index"))
const ErrorBoundary = loadable(() => import("./ErrorBoundary/index"))
const ToastContainer = React.lazy(() =>
  import("react-toastify").then(module => {
    return { default: module.ToastContainer }
  })
)
export const PageStateContext = createContext(null)

export default function PrimeAgile({ data, pageContext }) {
  const [load, setLoad] = useState(false)
  const appendScriptsOnScrollOptions = { once: true }

  const addCaptha = () => {
    pageContext.loadcaptha = true
    setLoad(true)
  }

  useEffect(() => {
    window.addEventListener(
      "mousemove",
      addCaptha,
      appendScriptsOnScrollOptions
    )

    return () => {
      window.removeEventListener(
        "mousemove",
        addCaptha,
        appendScriptsOnScrollOptions
      )
    }
  }, [])

  let TREE =
    data.primeAgile?.layoutWithObjectTree?.uiObjectWithChildTreeR
      ?.uiObjectChildTree

  TREE = pageBuilder(sortBy(TREE, ["objectOrder"]))
  const dispatch = useDispatch()

  var verifyCallba = function (response) {
    dispatch(setCaptchaResponse(response))
  }

  return (
    <>
      <SSRProvider>
        <ErrorBoundary>
          <ProviderAPI value={pageContext} id="primeagile__entry">
            <FontSchemeManager fontScheme={pageContext.fontScheme}>
              <PrimeAgileHelmet pageContext={pageContext} />
              <Translate pageContext={pageContext} />
              <Suspense fallback={<div />}>
                <ToastContainer />
              </Suspense>
              {pageContext?.loadcaptha && pageContext.captchaSiteKey ? (
                <GoogleReCaptchaProvider
                  reCaptchaKey={pageContext.captchaSiteKey}
                  scriptProps={{
                    async: false, // optional, default to false,
                    defer: true, // optional, default to false
                    appendTo: "head", // optional, default to "head", can be "head" or "body",
                    nonce: undefined, // optional, default undefined
                  }}
                >
                  <GoogleReCaptcha onVerify={verifyCallba} />
                  {TREE}
                </GoogleReCaptchaProvider>
              ) : (
                TREE
              )}
            </FontSchemeManager>
          </ProviderAPI>
        </ErrorBoundary>
      </SSRProvider>
    </>
  )
}

export const query = graphql`
  query layoutTree($site: String, $objectDataDefinitionId: String) {
    primeAgile {
      layoutWithObjectTree(
        objectDataDefinitionId: $objectDataDefinitionId
        layoutSetId: $site
      ) {
        css {
          fileId
          fileName
          fileType
          fileUrl
        }
        uiObjectWithChildTreeR {
          uiObjectChildTree
        }
      }
    }
  }
`
